
import './App.css';
import FileUpload from './components/FileUpload';
// import FileUploadDev from './components/FileUploadDev';

function App() {
  return (
    <div className="App">
     <FileUpload/>
     {/* <FileUploadDev/> */}
    </div>
  );
}

export default App;
