import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from "../logo.png";
import { ClipboardIcon } from '@heroicons/react/24/outline';

const FileUploadDev = () => {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy Link');
  
  const countdownTimer = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    if (!isLocked) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
        setError('');
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLocked) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (!isLocked) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setFile(droppedFile);
        setError('');
      }
    }
  };

  const handleUpload = async () => {
    if (!file || isLocked || isUploading) {
      return;
    }

    setIsUploading(true);
    setError('');
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://apps.maida.co:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      const fileLink = `https://apps.maida.co:5000/file/${response.data.fileId}/${encodeURIComponent(response.data.originalName)}`;
      setLink(fileLink);
      setIsUploading(false);
      setFile(null);

      // Start 10-second lock period
      setIsLocked(true);
      setCountdown(30);
    } catch (error) {
      setError('Upload failed. Please try again.');
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (isLocked && countdown > 0) {
      countdownTimer.current = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsLocked(false);
    }

    return () => {
      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current);
      }
    };
  }, [isLocked, countdown]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopySuccess(true);
        setCopyButtonLabel('Link Copied'); // Change button text to "Link Copied"
        
        // Reset the button label after 2 seconds
        setTimeout(() => {
          setCopySuccess(false);
          setCopyButtonLabel('Copy Link'); // Reset button text back to "Copy Link"
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {/* Logo at top-left corner */}
      <div className="absolute w-24 h-24 top-8 left-8">
        <img src={logo} alt="Logo" className="object-contain w-full h-full" />
      </div>

      {/* Developer credit at bottom-right corner */}
      <div className="absolute bottom-4 right-4">
        <a
          href="https://Maverickphp.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-blue-600 hover:underline"
        >
          Developed by Maverickphp.com
        </a>
      </div>

      <div className="w-full max-w-md overflow-hidden bg-white rounded-lg shadow-md">
        <div className="p-6">
          <h1 className="mb-6 text-2xl font-bold text-center text-gray-800">File Upload</h1>

          <div className="space-y-4">
            <div
              className={`flex flex-col items-center justify-center w-full h-48 border-2 border-dashed rounded-lg transition-colors ${isLocked ? 'border-gray-300' : isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
                }`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!isLocked ? (
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
                  <svg className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500">
                    Upload one file at a time
                  </p>
                </label>
              ) : (
                <p className="text-sm font-semibold text-red-500">
                  Upload locked for {countdown} seconds
                </p>
              )}
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                ref={fileInputRef}
                disabled={isLocked}
              />
            </div>

            {file && (
              <p className="text-sm text-center text-gray-600">
                Selected file: {file.name}
              </p>
            )}

            <button
              onClick={handleUpload}
              disabled={!file || isLocked || isUploading}
              className={`w-full p-3 text-sm font-semibold tracking-wide text-white uppercase transition-colors duration-200 transform rounded-lg ${!file || isLocked || isUploading ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                }`}
            >
              {isUploading ? 'Uploading...' : 'Upload File'}
            </button>

            {isUploading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}

            {error && (
              <p className="text-sm text-center text-red-500">{error}</p>
            )}

            {link && (
              <div
                className="p-4 text-sm text-green-800 bg-green-100 border border-green-300 rounded-lg"
                role="alert"
              >
                <p className="font-bold">File uploaded successfully!</p>
                <p>Your file link (valid for 3 days):</p>
                <div className="flex items-center mt-2 space-x-2">
                  <a
                    href={link}
                    className="flex-1 text-blue-600 break-all hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link}
                  </a>
                  <button
                    onClick={copyToClipboard}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <ClipboardIcon className="w-5 h-5 mr-2" />
                    {copyButtonLabel}
                  </button>
                </div>
                {copySuccess && (
                  <div className="fixed z-50 p-4 text-white transition-opacity duration-300 ease-in-out bg-green-500 rounded-lg shadow-md top-4 right-4">
                    Link copied to clipboard!
                  </div>
                )}
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadDev;
